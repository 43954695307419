// Generated by Framer (f4b44ab)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["I142:8763;198:5030", "a1TXVkdom"];

const serializationHash = "framer-n3c6n"

const variantClassNames = {"I142:8763;198:5030": "framer-v-1684ecw", a1TXVkdom: "framer-v-18q1r1z"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Center: "I142:8763;198:5030", Left: "a1TXVkdom"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "I142:8763;198:5030"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "I142:8763;198:5030", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-n3c6n", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1684ecw", className)} data-framer-name={"Center"} layoutDependency={layoutDependency} layoutId={"I142:8763;198:5030"} ref={ref ?? ref1} style={{background: "linear-gradient(89.99999962647408deg, rgba(0, 0, 0, 0) 0%, var(--token-2930fc96-2a19-4c51-bdb3-fa6e89c47e8a, rgb(188, 236, 0)) /* {\"name\":\"Primary 100\"} */ 52.08333134651184%, rgba(10, 4, 0, 0) 100%)", borderBottomLeftRadius: 500, borderBottomRightRadius: 500, borderTopLeftRadius: 500, borderTopRightRadius: 500, ...style}} variants={{a1TXVkdom: {background: "linear-gradient(89.99999962647408deg, var(--token-2930fc96-2a19-4c51-bdb3-fa6e89c47e8a, rgb(188, 236, 0)) /* {\"name\":\"Primary 100\"} */ 0%, rgba(0, 0, 0, 0) 100%, rgba(10, 4, 0, 0) 100%)"}}} {...addPropertyOverrides({a1TXVkdom: {"data-framer-name": "Left"}}, baseVariant, gestureVariant)}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-n3c6n [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-n3c6n .framer-1kqiy0o { display: block; }", ".framer-n3c6n .framer-1684ecw { height: 12px; position: relative; width: 150px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 12
 * @framerIntrinsicWidth 150
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"a1TXVkdom":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerAr2bjiooN: React.ComponentType<Props> = withCSS(Component, css, "framer-n3c6n") as typeof Component;
export default FramerAr2bjiooN;

FramerAr2bjiooN.displayName = "Global/Vector";

FramerAr2bjiooN.defaultProps = {height: 12, width: 150};

addPropertyControls(FramerAr2bjiooN, {variant: {options: ["I142:8763;198:5030", "a1TXVkdom"], optionTitles: ["Center", "Left"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerAr2bjiooN, [])